import _ from "lodash";
import React, { Fragment, useState, useEffect } from "react";
import { setLocaleData, setLocaleCountry } from "actions";
import { connect, useSelector } from "react-redux";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { IntlActions, useTranslate } from "react-redux-multilingual";
import { Link, useLocation } from "react-router-dom";
import { iconFontSize } from "/constants";
import LocalesValues from "components/standart/LocalesValues";
import Col3Container from "components/standart/col3Container";
import { LiveIndicator } from "components/standart";
import { ContactsButtons } from "components/standart/contactsButtons";
import { TelegramContacts } from "components/standart/TelegramContacts";
import { frontendApi, setSuccess } from "reducers/api";
import { Helmet } from "react-helmet-async";
import { useDemoPlay } from "components/common/player/refs/demo";

const { api } = frontendApi.actions;

const SideMenu = (props) => {
  const { session } = useSelector((state) => state.user);
  const translate = useTranslate();
  const { settings = {} } = { ...props.env };

  const location = useLocation();
  const [menuOpened, setMenuOpened] = useState(false);
  // на любом переходе закроем меню
  useEffect(() => {
    setMenuOpened(false);
  }, [location]);

  const { demoPlayRef } = useDemoPlay();
  const toggleMenuHandler = () => {
    demoPlayRef.current.hideDemoChannel();
    setMenuOpened(!menuOpened);
  };
  const style = { fontSize: iconFontSize, cursor: "pointer" };

  const className = "text-black text-decoration-none";
  return (
    <>
      <span className="-btn -btn-sm" onClick={toggleMenuHandler} style={{ ...style }}>
        <i className="far fa-bars" />
      </span>
      <Modal
        isOpen={menuOpened}
        centered
        toggle={toggleMenuHandler}
        wrapClassName={"fullscreen-modal"}
      >
        <ModalHeader toggle={toggleMenuHandler}></ModalHeader>
        <ModalBody>
          <Col3Container>
            <div className="text-center">
              <div className="large-header mb-2">
                <Link to={"/"} {...{ className }}>
                  {translate("menu_index")}
                </Link>
              </div>
              <div className="large-header mb-2">
                <Link to={"/join"} {...{ className }}>
                  {translate("menu_channels")}
                </Link>
              </div>
              {/* 
              <div className="large-header mb-2">
                <Link to={"/ad"} {...{ className }}>
                  {translate("menu_adverts")}
                </Link>
              </div>
              */}
              <div className="large-header mb-2">
                <Link to={"/about"} {...{ className }}>
                  {translate("menu_about")}
                </Link>
              </div>
              {/* 
              <div className="large-header mb-2">
                <a
                  href={`${settings.telegramChannel}?livestream`}
                  target="_blank"
                  {...{ className }}
                >
                  {translate("translation_title")}
                </a>
                <div className="mb-2"/>
                <LiveIndicator />
              </div>
                */}
              <div className="mb-5"></div>
              {/* 
              <div className="large-header mb-5">
                <Link to={"/account"} {...{ className }}>
                  {translate("menu_account")}{" "}
                  <i className={`fa-heart ${session.account ? "fas text-danger" : "far"}`} />
                </Link>
              </div>
              */}

              <TelegramContacts color={"black"} />
              {/* <ContactsButtons data={settings} color="text-black" /> */}
            </div>
          </Col3Container>
        </ModalBody>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  env: state.frontend.env,
});

export default connect(mapStateToProps, {})(SideMenu);
