import React from "react";
import ReactDOM from "react-dom/client";
// import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { IntlProvider } from "react-redux-multilingual";

import "bootstrap/dist/css/bootstrap.min.css";
import "pretty-checkbox/src/pretty-checkbox.scss";

import "./index.scss";

// Import custom components
import store from "store";

import translations from "./constants/translations";

// import Layout from "./views/layout";
import RoutesMap from "./routes";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <HelmetProvider>
      {/* <PersistGate loading={null} persistor={persistor}> */}
      <IntlProvider translations={translations} locale="ru">
        <BrowserRouter>
          <RoutesMap />
        </BrowserRouter>
      </IntlProvider>
    </HelmetProvider>
    {/* </PersistGate> */}
  </Provider>
  // </React.StrictMode>,
);
